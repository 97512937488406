<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划">
                                        <el-cascader style="width: 160px;margin-right: 10px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable @change="handleChange"></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
<!--                                    <el-form-item label="建设类别">-->
<!--                                        <el-select v-model="gllx" placeholder="请选择" style="width: 170px">-->
<!--                                            <el-option label="" value="">全部</el-option>-->
<!--                                            <el-option label="高速公路" value="GaoSu">高速公路</el-option>-->
<!--                                            <el-option label="普通国道" value="GuoDao">普通国道</el-option>-->
<!--                                            <el-option label="普通省道" value="ShengDao">普通省道</el-option>-->
<!--                                        </el-select>-->
<!--                                    </el-form-item>-->
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 170px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设主体">
                                        <el-input style="width: 160px" size="medium" v-model="frdw" placeholder="请输入" clearable></el-input>
                                    </el-form-item>

                                    <el-form-item label="年份批次">
                                        <el-cascader
                                                :options="yearsToNow"
                                                collapse-tags
                                                :props="{ multiple: false }"
                                                @change="changeYear"
                                                clearable>

                                        </el-cascader>                                    </el-form-item>
<!--                                    <el-form-item label="风险项目">-->
<!--                                        <el-cascader size="medium" style="width: 170px" v-model="checkedFxxm" :options="fxxmList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
                                    <!--                                    <el-form-item label="建设性质">-->
                                    <!--                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsgm" :options="jsgmList" placeholder="请选择"  :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
                                    <!--                                        </el-cascader>-->
                                    <!--                                    </el-form-item>-->
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px;margin-left: 10px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <!--                                <span class="fright">-->
                                <!--                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>-->
                                <!--                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">新增</el-button>-->
                                <!--                                </span>-->
                               <div style="display: flex;align-items: center;justify-content: space-between">
                                   <h3 class="tit">公路列表</h3>
                                   <el-button @click="exportData()" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                               </div>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{totalInfo.xmsl}}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj2.png" alt="">
                                        <div class="t">
                                            <h5>建设规模-里程<small>（公里）</small></h5>
                                            <b class="date-num">{{ totalInfo.jsgm }}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-3">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>总投资<small>（万元）</small></h5>
                                            <b class="date-num">{{ totalInfo.ztz }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-4">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>总投资中央车购税<small>（万元）</small></h5>
                                            <b class="date-num">{{ totalInfo.ztzzycgs }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>当年计划合计<small>（万元）</small></h5>
                                            <b class="date-num">{{ totalInfo.dnjh }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-6">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>中央车购税<small>（万元）</small></h5>
                                            <b class="date-num">{{ totalInfo.zycgs }}</b>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <el-tabs v-model="activeRoad" @tab-click="changeRoad" style="padding-left: 20px">
                                <el-tab-pane label="全部" name="全部"></el-tab-pane>
                                <el-tab-pane label="高速公路" name="高速公路"></el-tab-pane>
                                <el-tab-pane label="普通国道" name="普通国道"></el-tab-pane>
                                <el-tab-pane label="普通省道" name="普通省道"></el-tab-pane>
                                <el-tab-pane label="G331及质量提升工程" name="G331及质量提升工程"></el-tab-pane>
                                <el-tab-pane label="其他" name="其他"></el-tab-pane>
                            </el-tabs>
                            <div class="box-content">
                                <el-table :highlight-current-row="true" :row-class-name="tableRowClassName" @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column fixed prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column fixed prop="" align="center" :width="superVip ? 240 : 180" label="操作">
                                        <template slot-scope="scope">
                                            <!--                                                        <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.XMXH}" @click="showMaps1(scope.row)"><i class="el-icon-location icons"></i>路况评定</span>-->
                                            <!--                                                        <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.XMXH}" @click="showMaps(scope.row)"><i class="el-icon-location icons"></i>占地</span>-->
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                            <span class="newicon iconsyes" v-if="superVip" @click="edit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>编辑</span>
                                            <span class="newicon iconsyes" v-if="superVip"  @click="deletedItem(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>删除</span>
                                            <el-popconfirm  confirm-button-text='确认' @confirm="toTb(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否确定上报该项目？">
                                                <template #reference>
                                                    <span v-if="isAdmin" class="newicon iconsyes" ><i class="el-icon-edit-outline icons iconsyes"></i>上报</span>
                                                </template>
                                            </el-popconfirm>
                                            <!--                                                        <span class="newicon iconsyes" @click="openTable(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>历年</span>-->
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed prop="JHND" label="计划年度" align="center" show-overflow-tooltip :width="100" ></el-table-column>
                                    <el-table-column fixed prop="JHPC" label="计划批次" align="center" show-overflow-tooltip :width="100" ></el-table-column>
                                    <el-table-column prop="XMMC" label="建设项目" align="center" show-overflow-tooltip :width="320">
                                        <template slot-scope="scope">
                                            <div style="display: flex;align-items: center">
                                                <div v-if="scope.row.ZDFX == '是' || scope.row.ZJFX == '是'"><img style="width: 20px;height: 20px;display: block" src="../../assets/icon/fx.png" alt=""></div>
                                                <div>{{scope.row.XMMC}}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="建设年限"  align="center">
                                        <el-table-column prop="KGN" label="开工年"  align="center"></el-table-column>
                                        <el-table-column prop="WGN" label="完工年"  align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column label="总投资(万元)"  align="center">
                                        <el-table-column prop="JHZZJHJ" label="合计"  :width="100" align="right">
                                            <template slot-scope="scope">
                                                <div style="text-align: right">
                                                    {{scope.row.JHZZJHJ ? thousandBitSeparator(scope.row.JHZZJHJ.toFixed(0) ): ''}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="JHZZJZYTZ" label="中央车购税" :width="120"  align="right">
                                            <template slot-scope="scope">
                                                <div style="text-align: right">
                                                    {{scope.row.JHZZJZYTZ ? thousandBitSeparator(scope.row.JHZZJZYTZ.toFixed(0) ): ''}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                    <el-table-column label="当年计划(万元)"  align="center">
                                        <el-table-column prop="NJHTZHJ" label="合计" :width="120"  align="right">
                                            <template slot-scope="scope">
                                                <div style="text-align: right">
                                                    {{scope.row.NJHTZHJ ? thousandBitSeparator(scope.row.NJHTZHJ.toFixed(0) ): ''}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="NJHTZZYTZ" label="中央车购税" :width="100"  align="right">
                                            <template slot-scope="scope">
                                                <div style="text-align: right">
                                                    {{scope.row.NJHTZZYTZ ? thousandBitSeparator(scope.row.NJHTZZYTZ.toFixed(0) ): ''}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="NJHTZDFZC" label="地方配套"  :width="90"  align="right"></el-table-column>
                                        <el-table-column prop="NJHTZCZZJ" label="财政资金" :width="90"  align="right"></el-table-column>
                                        <el-table-column prop="NJHTZZXZQ" label="专项债券" :width="90"  align="right"></el-table-column>
                                        <el-table-column prop="NJHTZCPYSF" :width="120" label="成品油税费改革转移支付资金"  align="right"></el-table-column>
                                        <el-table-column prop="NJHTZYHDK" label="银行贷款"  align="right"></el-table-column>
                                        <el-table-column prop="NJHTZZQYZC" label="企业自筹"  align="right"></el-table-column>
                                        <el-table-column prop="NJHTZJSNR" label="主要建设内容" :width="120"  align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column prop="QQMC" label="对应前期项目" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                    <el-table-column prop="TBDW" label="建设主体" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                    <el-table-column prop="JSXZ" label="建设性质" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                    <el-table-column prop="XMLB" label="项目类别" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                    <el-table-column label="建设规模(公里)/(延米)" :width="150"  align="center">
                                        <el-table-column prop="JSGMHJ" label="合计" width="100" align="center"></el-table-column>
                                        <el-table-column prop="JSGMGS" label="高速" width="100" align="center"></el-table-column>
                                        <el-table-column prop="JSGMYJ" label="一级" width="100" align="center"></el-table-column>
                                        <el-table-column prop="JSGMEJ" label="二级" width="120" align="center"></el-table-column>
                                        <!--                                                    <el-table-column prop="EJGL60" label="二级(60km/h)" width="120" align="center"></el-table-column>-->
                                        <el-table-column prop="JSGMSJ" label="三级" width="100" align="center"></el-table-column>
                                        <el-table-column prop="JSGMDQ" label="独立大桥" width="100" align="center"></el-table-column>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
<!--                                <el-tabs  v-model="firstYear"  tab-position="left" style="width: 100% " @tab-click="chooseYear"	 :height="tableHeight">-->
<!--                                    <el-tab-pane :label="item" v-for="(item,index) in yearList" :name="item" :key="index">-->
<!--                                       -->
<!--                                    </el-tab-pane>-->
<!--                                </el-tabs>-->
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <el-drawer :wrapperClosable="false" :visible.sync="showAddModel" @close="closeDrawer" size="1300px" :title="detail.XMMC"  class="editModal">
                <el-form :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="基本信息" name="0">
                            <div class="col">
                                <div class="col-1">
                                    <el-divider>基本信息</el-divider>
                                    <el-form-item label="建设项目" prop="XMMC"  >
                                        <el-input placeholder="建设项目" v-model="detail.XMMC"></el-input>
                                    </el-form-item>
                                    <el-form-item label='对应前期项目名称'>
                                        <el-input placeholder="建设项目" v-model="detail.QQMC"></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设主体" prop="TBDW"  >
                                        <el-input placeholder="单位名称" v-model="detail.TBDW"></el-input>
                                    </el-form-item>
                                    <el-form-item label="建设性质" prop="JSXZ">
                                        <el-select  placeholder="建设性质" v-model="detail.JSXZ">
                                            <el-option label="新建" value="新建"></el-option>
                                            <el-option label="升级改造" value="升级改造"></el-option>
                                            <el-option label="原级改造" value="原级改造"></el-option>
                                            <el-option label="路面改造" value="路面改造"></el-option>
                                            <el-option label="改扩建" value="改扩建"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目类别" prop="XMLB">
                                        <el-select  placeholder="项目类别" v-model="detail.XMLB">
                                            <el-option label="高速公路" value="高速公路"></el-option>
                                            <el-option label="普通国道" value="普通国道"></el-option>
                                            <el-option label="普通省道" value="普通省道"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="计划年度" prop="JHND"  >
                                        <el-input disabled placeholder="计划年度" v-model="detail.JHND"></el-input>
                                    </el-form-item>
                                    <el-form-item label="是否续建" prop="SFXJ"  >
                                        <el-input disabled  v-model="detail.SFXJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="开工年" prop="KGN"  >
                                        <el-input disabled placeholder="开工年" v-model="detail.KGN"></el-input>
                                    </el-form-item>
                                    <el-form-item label="完工年" prop="WGN"  >
                                        <el-input disabled placeholder="完工年" v-model="detail.WGN"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1">
                                    <el-divider>建设规模</el-divider>
                                    <el-form-item label="合计"   prop="JSGMHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="合计" disabled v-model="detail.JSGMHJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="高速公路"  prop="JSGMGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="高速公路" @input="changeJstotal($event,'gs')" v-model="detail.JSGMGS"></el-input>
                                    </el-form-item>
                                    <el-form-item label="一级"  prop="JSGMYJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="一级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMYJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="二级"   prop="JSGMEJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="二级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMEJ"></el-input>
                                    </el-form-item>
<!--                                    <el-form-item label="二级(60km/h)"   prop="EJGL60" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                        <el-input placeholder="二级" @input="changeJstotal($event,'gs')" v-model="detail.EJGL60"></el-input>-->
<!--                                    </el-form-item>-->
                                    <el-form-item label="三级"  prop="JSGMSJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="三级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="独立大桥"  prop="JSGMDQ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="独立大桥" @input="changeJstotal($event,'gs')" v-model="detail.JSGMDQ"><template #suffix>延米</template></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1">
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="资金" name="1">
<!--                           <div style="display: flex;justify-content: space-between">-->
<!--                               <table style="text-align:center">-->
<!--                                   <tr >-->
<!--                                       <td colspan="2" >-->
<!--                                           <div class="label3">总投资</div>-->
<!--                                       </td>-->
<!--                                   </tr>-->
<!--                                   <tr>-->
<!--                                       <td>-->
<!--                                           <div class="label2">合计</div>-->
<!--                                       </td>-->
<!--                                       <td>-->
<!--                                           <div class="label2">中央车购税</div>-->
<!--                                       </td>-->
<!--                                   </tr>-->
<!--                                   <tr>-->
<!--                                       <td>-->
<!--                                           <div class="cont">{{detail.JHZZJHJ}}</div>-->

<!--                                       </td>-->
<!--                                       <td>-->
<!--                                           <div class="cont">{{detail.JHZZJZYTZ}}</div>-->

<!--                                       </td>-->
<!--                                   </tr>-->
<!--                               </table>-->
<!--                               <table style="text-align:center">-->
<!--                                   <tr >-->
<!--                                       <td colspan="2" >-->
<!--                                           <div class="label3">累计完成投资</div>-->
<!--                                       </td>-->
<!--                                   </tr>-->
<!--                                   <tr>-->
<!--                                       <td>-->
<!--                                           <div class="label2">合计</div>-->
<!--                                       </td>-->
<!--                                       <td>-->
<!--                                           <div class="label2">中央车购税</div>-->
<!--                                       </td>-->
<!--                                   </tr>-->
<!--                                   <tr>-->
<!--                                       <td>-->
<!--                                           <div class="cont">{{detail.LJWCTZHJ}}</div>-->

<!--                                       </td>-->
<!--                                       <td>-->
<!--                                           <div class="cont">{{detail.LJWCTZZYTZ}}</div>-->

<!--                                       </td>-->
<!--                                   </tr>-->
<!--                               </table>-->
<!--                               <table style="text-align:center">-->
<!--                                   <tr >-->
<!--                                       <td colspan="2" >-->
<!--                                           <div class="label3">累计下达投资</div>-->
<!--                                       </td>-->
<!--                                   </tr>-->
<!--                                   <tr>-->
<!--                                       <td>-->
<!--                                           <div class="label2">合计</div>-->
<!--                                       </td>-->
<!--                                       <td>-->
<!--                                           <div class="label2">中央车购税</div>-->
<!--                                       </td>-->
<!--                                   </tr>-->
<!--                                   <tr>-->
<!--                                       <td>-->
<!--                                           <div class="cont">{{detail.LJXDHJ}}</div>-->

<!--                                       </td>-->
<!--                                       <td>-->
<!--                                           <div class="cont">{{detail.LJXDZYTZ}}</div>-->

<!--                                       </td>-->
<!--                                   </tr>-->
<!--                               </table>-->
<!--                           </div>-->
<!--                            <table style="margin-top: 20px;text-align:center">-->
<!--                                <tr >-->
<!--                                    <td >-->
<!--                                        <div class="label2">剩余车购税</div>-->
<!--                                    </td>-->
<!--                                    <td >-->
<!--                                        <div class="label2">剩余计划</div>-->
<!--                                    </td>-->
<!--                                    <td >-->
<!--                                        <div class="label2">报省委计划投资数</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.SYZJZYTZ}}</div>-->

<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.SYZJHJ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont">{{detail.BSWJHTZS}}</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                            </table>-->
<!--                            <table style="text-align:center;margin-top: 20px">-->
<!--                                <tr>-->
<!--                                    <td colspan="9" style="background: #fafafa">-->
<!--                                        <div class="cont" style="width: 100%;background: #f5f7fa;text-align: center">当年计划</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="label2" >合计</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">中央车购税</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">地方配套</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2" >财政资金</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2" >专项债券</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2" style="line-height: unset">成品油税费改革<br>转移支付资金</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2" >银行贷款</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">企业自筹</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="label2">主要建设内容</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZHJ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZZYTZ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px" >{{detail.NJHTZDFZC}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZCZZJ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZZXZQ}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZCPYSF}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZYHDK}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZZQYZC}}</div>-->
<!--                                    </td>-->
<!--                                    <td>-->
<!--                                        <div class="cont" style="width: 127px">{{detail.NJHTZJSNR}}</div>-->
<!--                                    </td>-->
<!--                                </tr>-->
<!--                            </table>-->

                            <table style="text-align:center;margin-top: 20px;width:100%"  class="littleTable">
                                <!-- <tr>
                                    <td colspan="11" style="background: #F5F7FA">
                                        <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">计划总投资</div>
                                    </td>
                                </tr> -->
                                <tr>
                                    <td rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2" >年度批次</div>
                                    </td>
                                    <td rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2" >合计</div>
                                    </td>
                                    <td rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2" style="line-height: 1.7;">中央投资 <div>（车购税）</div> </div>
                                    </td>
                                    <td rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2" style="line-height: 1.7;">省级补助资金 </div>
                                    </td>
                                    <td colspan="2">
                                        <div class="label2" >省配套资金</div>
                                    </td>
                                    <td  rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2"  >专项债券</div>
                                    </td>
                                    <td  rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2"  >省市县自筹</div>
                                    </td>
                                    <td colspan="3">
                                        <div class="label2" >地方配套</div>
                                    </td>
                                    <td  rowspan="3" style="background: #f5f7fa;">
                                        <div class="label2"  >主要建设内容</div>
                                    </td>
                                    <!-- <td  rowspan="2" style="background: #f5f7fa;">
                                        <div class="label2"  >新增生产能力</div>
                                    </td> -->
                                </tr>
                                <tr>
                                    <td>
                                        <div class="label2"  >财政资金</div>
                                    </td>
                                    <td>
                                        <div class="label2"  >成品油税费改革转移支付资金</div>
                                    </td>
                                    <td>
                                        <div class="label2"  >银行贷款</div>
                                    </td>
                                    <td>
                                        <div class="label2"  >企业自筹</div>
                                    </td>
                                    <td>
                                        <div class="label2"  >地方自筹</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="11" style="background: #F5F7FA">
                                        <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">计划总资金</div>
                                    </td>
                                </tr>
                                <tr class="newform">
                                    <td class="ty" style="width:122px;height: 42px">
<!--                                        <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>-->
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJHJ}}-->
                                        <el-form-item  prop="JHZZJHJ">
                                            <el-input size="medium"  type="number"  class="inputright" v-model="detail.JHZZJHJ" placeholder="合计"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJZYTZ}}-->
                                        <el-form-item prop="JHZZJZYTZ" >
                                            <el-input  size="medium"  type="number"  class="inputright" v-model="detail.JHZZJZYTZ" placeholder="中央投资"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
                                        <el-form-item prop="SJBZZJ" >
                                            <el-input  size="medium"  type="number"  class="inputright" v-model="detail.SJBZZJ" placeholder="省级补助资金"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJCZZJ}}-->
                                        <el-form-item prop="JHZZJCZZJ" >
                                            <el-input  size="medium"  type="number" class="inputright"  v-model="detail.JHZZJCZZJ" placeholder="财政资金"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJCPYSF}}-->
                                        <el-form-item prop="JHZZJCPYSF" >
                                            <el-input  type="number" class="inputright" style="width: 200px"   size="medium" v-model="detail.JHZZJCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJZXZQ}}-->
                                        <el-form-item prop="JHZZJZXZQ" >
                                            <el-input  type="number" class="inputright" size="medium"   v-model="detail.JHZZJZXZQ" placeholder="专项债券"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJSSXZC}}-->
                                        <el-form-item prop="JHZZJSSXZC" >
                                            <el-input  type="number" class="inputright"  size="medium" v-model="detail.JHZZJSSXZC" placeholder="省市县自筹"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJYHDK}}-->
                                        <el-form-item prop="JHZZJYHDK" >
                                            <el-input  type="number" class="inputright"   size="medium" v-model="detail.JHZZJYHDK" placeholder="银行贷款"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJQYZC}}-->
                                        <el-form-item prop="JHZZJQYZC" >
                                            <el-input  type="number" class="inputright"  size="medium" v-model="detail.JHZZJQYZC" placeholder="企业自筹"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
<!--                                        {{detail.JHZZJDFZC}}-->
                                        <el-form-item prop="JHZZJDFZC" >
                                            <el-input  type="number" class="inputright"  size="medium" v-model="detail.JHZZJDFZC" placeholder="地方自筹"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td class="ty">
                                        <el-form-item prop="JHZZJJSNR" style="width:160px">
                                            {{detail.JHZZJJSNR}}
<!--                                            <el-select v-model="detail.JHZZJJSNR" placeholder="请选择">-->
<!--                                                <el-option label="请选择" value=""></el-option>-->
<!--                                                <el-option label="路基桥涵" value="路基桥涵"></el-option>-->
<!--                                                <el-option label="路基路面" value="路基路面"></el-option>-->
<!--                                            </el-select>-->
                                            <!-- <el-input  size="medium" class="inputcenter" v-model="detail.JHZZJJSNR" placeholder="主要建设内容"></el-input> -->
                                        </el-form-item>
                                    </td>
                                    <!-- <td class="ty">
                                        <el-form-item prop="JHZZJXZSCNL"   style="width:100px">
                                            <el-input  size="medium" class="inputcenter" v-model="detail.JHZZJXZSCNL" placeholder="新增生产能力"></el-input>
                                        </el-form-item>
                                    </td> -->
                                </tr>
                                <tr>
                                    <td colspan="11" style="background: #F5F7FA">
                                        <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">累计下达资金</div>
                                    </td>
                                </tr>
                                <tr class="newform">
                                    <td class="ty" style="width:122px;height: 42px">
                                        <!--                                        <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDHJ}}
                                        <!--                                        <el-form-item  prop="JHZZJHJ">-->
                                        <!--                                            <el-input size="medium"  type="number" disabled class="inputright" v-model="detail.JHZZJHJ" placeholder="合计"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDZYTZ}}
                                        <!--                                        <el-form-item prop="JHZZJZYTZ" >-->
                                        <!--                                            <el-input  size="medium"  type="number" disabled class="inputright" v-model="detail.JHZZJZYTZ" placeholder="中央投资"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDSJBZZJ}}
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDCZZJ}}
                                        <!--                                        <el-form-item prop="JHZZJCZZJ" >-->
                                        <!--                                            <el-input  size="medium"  type="number" class="inputright" disabled v-model="detail.JHZZJCZZJ" placeholder="财政资金"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDCPYSF}}
                                        <!--                                        <el-form-item prop="JHZZJCPYSF" >-->
                                        <!--                                            <el-input  type="number" class="inputright" style="width: 200px"  disabled size="medium" v-model="detail.JHZZJCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDZXZQ}}
                                        <!--                                        <el-form-item prop="JHZZJZXZQ" >-->
                                        <!--                                            <el-input  type="number" class="inputright" size="medium"  disabled v-model="detail.JHZZJZXZQ" placeholder="专项债券"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDSSXZC}}
                                        <!--                                        <el-form-item prop="JHZZJSSXZC" >-->
                                        <!--                                            <el-input  type="number" class="inputright" disabled size="medium" v-model="detail.JHZZJSSXZC" placeholder="省市县自筹"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDYHDK}}
                                        <!--                                        <el-form-item prop="JHZZJYHDK" >-->
                                        <!--                                            <el-input  type="number" class="inputright"  disabled size="medium" v-model="detail.JHZZJYHDK" placeholder="银行贷款"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDZQYZC}}
                                        <!--                                        <el-form-item prop="JHZZJQYZC" >-->
                                        <!--                                            <el-input  type="number" class="inputright" disabled size="medium" v-model="detail.JHZZJQYZC" placeholder="企业自筹"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDDFZC}}
                                        <!--                                        <el-form-item prop="JHZZJDFZC" >-->
                                        <!--                                            <el-input  type="number" class="inputright" disabled size="medium" v-model="detail.JHZZJDFZC" placeholder="地方自筹"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">

                                    </td>
                                    <!-- <td class="ty">
                                        <el-form-item prop="JHZZJXZSCNL"   style="width:100px">
                                            <el-input  size="medium" class="inputcenter" v-model="detail.JHZZJXZSCNL" placeholder="新增生产能力"></el-input>
                                        </el-form-item>
                                    </td> -->
                                </tr>


                                <tr>
                                    <td colspan="11" style="background: #F5F7FA;">
                                        <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">累计完成投资</div>
                                    </td>
                                </tr>
                                <tr class="newform" style="width:122px;height: 42px">
                                    <td class="ty" style="width:122px">
<!--                                        <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZHJ}}
<!--                                        <el-form-item  prop="LJWCTZHJ">-->
<!--                                            <el-input size="medium" class="inputright" disabled v-model="detail.LJWCTZHJ" placeholder="合计"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZZYTZ}}
<!--                                        <el-form-item prop="LJWCTZZYTZ">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZZYTZ" placeholder="中央投资"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTSJBZZJ}}
                                        <!--                                        <el-form-item prop="LJWCTZZYTZ">-->
                                        <!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZZYTZ" placeholder="中央投资"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDCZZJ}}
<!--                                        <el-form-item prop="LJXDCZZJ">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJXDCZZJ" placeholder="财政资金"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDCPYSF}}
<!--                                        <el-form-item prop="LJXDCPYSF">-->
<!--                                            <el-input style="width: 200px" size="medium" class="inputright" disabled v-model="detail.LJXDCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZZXZQ}}
<!--                                        <el-form-item prop="LJWCTZZXZQ">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZZXZQ" placeholder="专项债券"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZSSXZC}}
<!--                                        <el-form-item prop="LJWCTZSSXZC">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZSSXZC" placeholder="省市县自筹"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZYHDK}}
<!--                                        <el-form-item prop="LJWCTZYHDK">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZYHDK" placeholder="银行贷款"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZQYZC}}
<!--                                        <el-form-item prop="LJWCTZQYZC">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZQYZC" placeholder="企业自筹"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJXDDFZC}}
<!--                                        <el-form-item prop="LJXDDFZC">-->
<!--                                            <el-input  size="medium" class="inputright" disabled v-model="detail.LJXDDFZC" placeholder="地方自筹"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.LJWCTZJSNR}}
<!--                                        <el-form-item prop="LJWCTZJSNR" >-->
<!--                                            <el-input  size="medium" class="inputcenter" disabled v-model="detail.LJWCTZJSNR" placeholder="主要建设内容"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
<!--                                       <el-form-item prop="LJWCTZXZSCNL"   style="width:100px">-->
<!--                                           <el-input  size="medium" class="inputcenter" disabled v-model="detail.LJWCTZXZSCNL" placeholder="新增生产能力"></el-input>-->
<!--                                       </el-form-item>-->
                                   </td>
                                </tr>
                                <tr>
                                    <td colspan="11" style="background: #F5F7FA">
                                        <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">{{detail.JHND}}年计划资金</div>
                                    </td>
                                </tr>
                                <tr class="newform" style="width:122px;height: 42px">
                                    <td class="ty" style="width:122px">
<!--                                        <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>-->
                                    </td>
                                    <td class="ty">
                                        <el-form-item  v-if="superVip">
                                            <el-input size="medium"  class="inputright" v-model="detail.NJHTZHJ" placeholder="合计"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZHJ}}</div>
                                    </td>
                                    <td class="ty">
                                        <el-form-item  v-if="superVip" prop="NJHTZZYTZ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  size="medium" class="inputright"   type="number" v-model="detail.NJHTZZYTZ" placeholder="中央投资"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZZYTZ}}</div>
                                    </td>
                                    <td class="ty">
                                        <el-form-item  v-if="superVip" prop="NJHTZSJBBZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                            <el-input  size="medium" class="inputright"   type="number" v-model="detail.NJHTZSJBZZJ" placeholder="省级补助资金"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZSJBZZJ}}</div>
                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZCZZJ" >
                                            <el-input  size="medium" class="inputright"   type="number" v-model="detail.NJHTZCZZJ" placeholder="财政资金"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZCZZJ}}</div>
                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZCPYSF" >
                                            <el-input style="width: 200px" size="medium" class="inputright"   type="number" v-model="detail.NJHTZCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZCPYSF}}</div>

                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZZXZQ" >
                                            <el-input  size="medium" class="inputright"  type="number"  v-model="detail.NJHTZZXZQ" placeholder="专项债券"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZZXZQ}}</div>

                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZSSXZC">
                                            <el-input  size="medium" class="inputright"  v-model="detail.NJHTZSSXZC" placeholder="省市县自筹"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZSSXZC}}</div>

                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZYHDK" >
                                            <el-input  size="medium" class="inputright"  type="number" v-model="detail.NJHTZYHDK" placeholder="银行贷款"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZYHDK}}</div>

                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZZQYZC" >
                                            <el-input  size="medium" class="inputright"  type="number" v-model="detail.NJHTZZQYZC" placeholder="企业自筹"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZZQYZC}}</div>

                                    </td>
                                    <td class="ty">
                                        <el-form-item v-if="superVip" prop="NJHTZDFZC" >
                                            <el-input  size="medium" class="inputright"  type="number" v-model="detail.NJHTZDFZC" placeholder="地方自筹"></el-input>
                                        </el-form-item>
                                        <div v-else>{{detail.NJHTZDFZC}}</div>

                                    </td>
                                    <td class="ty">
                                        {{detail.NJHTZJSNR}}
<!--                                        <el-form-item prop="NJHTZJSNR" style="width:160px" :rules="[{ required: true,message: '内容不能为空',trigger: 'blur' }]">-->
<!--                                            &lt;!&ndash; <el-input  size="medium" class="inputcenter" v-model="detail.NJHTZJSNR" placeholder="主要建设内容"></el-input> &ndash;&gt;-->
<!--                                            <el-select v-model="detail.NJHTZJSNR" placeholder="请选择">-->
<!--                                                <el-option label="请选择" value=""></el-option>-->
<!--                                                <el-option label="路基桥涵" value="路基桥涵"></el-option>-->
<!--                                                <el-option label="路基路面" value="路基路面"></el-option>-->
<!--                                            </el-select>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
<!--                                       <el-form-item prop="NJHTZXZSCNL"   style="width:100px">-->
<!--                                           <el-input  size="medium" class="inputcenter" v-model="detail.NJHTZXZSCNL" placeholder="新增生产能力"></el-input>-->
<!--                                       </el-form-item>-->
                                   </td>
                                </tr>
                                <tr>
                                    <td colspan="11" style="background: #F5F7FA">
                                        <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">剩余资金</div>
                                    </td>
                                </tr>
                                <tr class="newform" style="width:122px;height: 42px">
                                    <td class="ty" style="width:122px">
<!--                                        <el-input size="medium" class="inputcenter"  placeholder="-" disabled></el-input>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJHJ}}
<!--                                        <el-form-item  prop="SYZJHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNumFs(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input size="medium" class="inputright"  :class="{colors:detail.SYZJHJ<0}" disabled v-model="detail.SYZJHJ" placeholder="合计"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJZYTZ}}
<!--                                        <el-form-item prop="SYZJZYTZ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNumFs(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZYTZ<0}" disabled v-model="detail.SYZJZYTZ" placeholder="中央投资"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJSJBZZJ}}
                                        <!--                                        <el-form-item prop="SYZJZYTZ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNumFs(rule, value, callback)},trigger: 'blur' }]">-->
                                        <!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZYTZ<0}" disabled v-model="detail.SYZJZYTZ" placeholder="中央投资"></el-input>-->
                                        <!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJCZZJ}}
<!--                                        <el-form-item prop="SYZJCZZJ" >-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJCZZJ<0}" disabled v-model="detail.SYZJCZZJ" placeholder="财政资金"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJCPYSF}}
<!--                                        <el-form-item prop="SYZJCPYSF" >-->
<!--                                            <el-input style="width: 200px" :class="{colors:detail.SYZJCPYSF<0}" size="medium" class="inputright"  disabled v-model="detail.SYZJCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJZXZQ}}
<!--                                        <el-form-item prop="SYZJZXZQ" >-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZXZQ<0}" disabled v-model="detail.SYZJZXZQ" placeholder="专项债券"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJSSXZC}}
<!--                                        <el-form-item prop="SYZJSSXZC" >-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJSSXZC<0}" disabled v-model="detail.SYZJSSXZC" placeholder="省市县自筹"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJYHDK}}
<!--                                        <el-form-item prop="SYZJYHDK" >-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJYHDK<0}" disabled v-model="detail.SYZJYHDK" placeholder="银行贷款"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJZQYZC}}
<!--                                        <el-form-item prop="SYZJZQYZC" >-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZQYZC<0}" disabled v-model="detail.SYZJZQYZC" placeholder="企业自筹"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJDFZC}}
<!--                                        <el-form-item prop="SYZJDFZC" >-->
<!--                                            <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJDFZC<0}" disabled v-model="detail.SYZJDFZC" placeholder="地方自筹"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
                                        {{detail.SYZJJSNR}}
<!--                                        <el-form-item prop="SYZJJSNR" style="width:160px">-->
<!--                                            <el-input  size="medium" class="inputcenter"  v-model="detail.SYZJJSNR" disabled placeholder="主要建设内容"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                    <td class="ty">
<!--                                        <el-form-item prop="SYZJXZSCNL"   style="width:100px">-->
<!--                                            <el-input  size="medium" class="inputcenter"   v-model="detail.SYZJXZSCNL" disabled placeholder="新增生产能力"></el-input>-->
<!--                                        </el-form-item>-->
                                    </td>
                                </tr>
                            </table>
                        </el-tab-pane>
                    </el-tabs>
                </el-form>
                <div class="footer" v-if="superVip">
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="cancel">取消</el-button>
                </div>
            </el-drawer>
            <el-drawer :visible.sync="assign" :title="detail.XMMC" size="80%">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" style="margin-top: 42px;display:flex">
                    <div class="search">
                        <el-form-item label="年份" style="margin-right: 10px">
                            <el-date-picker
                                    v-model="formInline.year"
                                    type="year"
                                    format="yyyy 年"
                                    value-format="yyyy"
                                    placeholder="选择年">
                            </el-date-picker>
                            <!--                            <el-input style="width: 300px" v-model="formInline.XMMC" placeholder="请输入项目名称"></el-input>-->
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="getCompanyList">搜索</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table :data="companyData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column  prop="Year" :width="100" label="年度" align="center" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column  prop="CGS" label="固定资产投资计划" align="center" show-overflow-tooltip>
                            <el-table-column  prop="CGS" label="车购税" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column  prop="WH1" :width="300" align="center" label="车购税文号" show-overflow-tooltip ></el-table-column>
                        </el-table-column>
                        <el-table-column  prop="CGS" label="建设投资计划(大本计划)" align="center" show-overflow-tooltip>
                            <el-table-column  prop="HJ" label="合计" align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column  prop="DFPT" label="地方配套" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="CZZJ" label="财政资金" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="ZXZQ" label="专项债券" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="YHDK" :width="220" align="center" label="成品油税费改革转移支付资金" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="CPY" label="银行贷款" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="QYZC" label="企业自筹" align="center" show-overflow-tooltip ></el-table-column>
                            <el-table-column  prop="WH2" label="文号" :width="220" align="center" show-overflow-tooltip ></el-table-column>
                        </el-table-column>
                        <el-table-column width="150"  prop="name" label="附件" align="center">
                            <template slot-scope="scope">
                                <el-popover trigger="hover" placement="top" v-if="scope.row.FJ.length > 0">
                                    <ul class="fj-list">
                                        <li v-for="(item,i) in scope.row.FJ" :key="i">
                                            <a target="view_window" :href="baseUrl+item.url+item.name">
                                                {{item.name}}
                                            </a>
                                            <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                        </li>
                                    </ul>

                                    <div slot="reference" class="name-wrapper">
                                        <el-tag :type="scope.row.FJ.length>0?'success':'danger'" size="small">已上传{{scope.row.FJ.length}}个</el-tag>
                                    </div>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>

            <el-drawer :visible.sync="showSswxm" @close="closeDrawer1" title="对应部十四五交通运输专项建设规划项目" size="80%">
                <el-form :inline="true" :model="sswform" class="demo-form-inline" style="margin-top: 42px;display:flex">
                    <div class="search">
                        <el-form-item label="项目名称">
                            <el-input style="width: 300px; margin-right: 10px" v-model="sswform.XMMC" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="getSWWXMList()">搜索</el-button>
                        <el-button type="primary" @click="confirmCompany">确定</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table   @sort-change="changeSort"  ref="multipleTable" :data="sswxmList" size="small " border  style="width: 100%">
                        <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                        <el-table-column prop="SZDS"  label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
                        <el-table-column prop="QQGZJZ" label="前期工作进展" :width="400" align="center" show-overflow-tooltip>
                            <el-table-column prop="JSGMGS" label="工可研" width="90" align="right">
                                <template slot-scope="scope">
                                    <div :style="{color:scope.row.GKBLZT == 0 ? '' : scope.row.GKBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                        {{scope.row.GKBLZT == 0 ? '未开展' : scope.row.GKBLZT == '1' ? '办理中' : '已办结'}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="初步设计" width="90" align="right">
                                <template slot-scope="scope">
                                    <div :style="{color:scope.row.CBSJBLZT == 0 ? '' : scope.row.CBSJBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                        {{scope.row.CBSJBLZT == 0 ? '未开展' : scope.row.CBSJBLZT == '1' ? '办理中' : '已办结'}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="施工图" width="90" align="right">
                                <template slot-scope="scope">
                                    <div :style="{color:scope.row.SGTBLZT == 0 ? '' : scope.row.SGTBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                        {{scope.row.SGTBLZT == 0 ? '未开展' : scope.row.SGTBLZT == '1' ? '办理中' : '已办结'}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="QQGZJZ" label="前期工作进展描述"  align="center" show-overflow-tooltip :width="150"></el-table-column>
                        <el-table-column prop="ZTZ" label="总投资(万元)" :width="120" align="center" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWGHXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>
                        <el-table-column prop="JSXZ" label="建设性质"  align="center" show-overflow-tooltip :width="150"></el-table-column>
                        <el-table-column prop="XMXZ" label="项目性质" sortable align="center" show-overflow-tooltip :width="150"></el-table-column>
                        <el-table-column label="建设规模" :width="150"  align="center">
                            <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column label="特大桥" width="50" align="center">
                                <el-table-column prop="JSGMTDQ" label="座数(座)" width="90" align="right"></el-table-column>
                                <el-table-column prop="JSGMTDQYM" label="延米数(米)" width="90" align="right"></el-table-column>
                            </el-table-column>
                            <el-table-column label="隧道" width="50" align="center">
                                <el-table-column prop="JSGMSD" label="座数(座)" width="90" align="right"></el-table-column>
                                <el-table-column prop="JSGMSDYM" label="延米数(米)" width="90" align="right"></el-table-column>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="XMFRDW" label="项目法人单位" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                        <el-table-column prop="QQGZFZR" label="前期工作负责人" :width="150" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="LXDH" label="联系电话" :width="150" align="center" show-overflow-tooltip></el-table-column>
                    </el-table>

                    <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30,40]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap" class="mapdialog mapdialog1">
            <Map v-if="dialogTableVisibleMap" :dialogData="dialogTableVisibleData" />
        </el-dialog>
        <el-dialog :title="dialogTableVisibleData1.XMMC" :visible.sync="dialogTableVisibleMap1" class="mapdialog mapdialog1">
            <LKPD v-if="dialogTableVisibleMap1" :dialogData="dialogTableVisibleData1" />
        </el-dialog>

    </div>
</template>
<script>
    // import FlowXmk from "../../components/Query/Road/GhkFlow";
    import Map from "../AMap/Box1.vue";
    import LKPD from "../AMap/Box2.vue";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    // import Flow from "../../components/Query/Road/Flow";
    import http from "../../api/http";
    // import FlowTable from "../../components/Query/Road/FlowTable";
    export default {
        name: "investment",
        components: {Map,LKPD },
        data() {
            return {
                superVip:false,
                JHND:[],
                JHPC:'',
                yearsToNow:[],
                options:[],
                totalInfo:{},
                activeRoad: '全部',
                jhnds:'',
                firstYear:'全部',
                yearList:['全部','2016','2017','2018','2019','2020','2021','2022','2023'],
                dialogTableVisibleData:{},
                dialogTableVisibleMap: false,
                dialogTableVisibleData1:{},
                dialogTableVisibleMap1: false,
                showSswxm: false,
                SSWXMID:'',
                editStatus: false,
                activeName:'0',
                required: false,
                innerDrawer: false,
                title:'新增建设单位',
                ruleForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                XMFRDW:'',
                XMFRDWID:'',
                frCompany:false,
                frCompanyList:[],
                frdw:'',
                frForm:{},
                formInline:{},
                sswform:{},
                assign: false,
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                },
                showDetail: false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                gkbz:false,
                singleData:{
                    wlyd:0,
                    nyd:0
                },
                XMMC:'',
                addStatus: true,
                Collapse:['1','2','3'],
                step:['一、工可','二、初步设计','三、施工图设计','四、施工前准备','五、县域国土空间规划'],
                activeProcess:0,
                showEditModel: false,
                confirmSSW:[],
                tableHeight:0, //表格高度
                activeIndex: true, //显隐高级搜索
                /**查询条件 begin*/
                xmmc: "", //项目名称
                xmnd: "", //项目年度
                gllx: "", //建设类别
                checkedXmxz: [], //项目性质
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                checkedXmlb: [], //项目类别
                checkedJsgm: [], //项目类别
                checkedGk: [], //项目类别
                checkedFxxm: [], //项目类别
                checkedSgtsj: [], //项目类别
                checkedCbsj: [], //项目类别
                region: [""], //已选中行政区划
                qqgzjz: [], //前期工作进展
                /**查询条件 end*/
                xmxzShow: true, //项目性质查询条件显隐
                jsxzShow: true, //建设性质查询条件显隐
                xmlxShow: true, //项目类型查询条件显隐
                checkList: [],
                checkAllXmlb: false,
                checkAllXmxz: false,
                checkAllJsxz: false,
                checkAllXmlx: false,
                checkAllJsgm: false,
                checkAllGk: false,
                checkAllCbsj: false,
                checkAllSgtsj: false,
                jsxzList: [{value: "新建", label: "新建", disabled: false,}, {value: "续建", label: "续建", disabled: false,},{value: "改扩建", label: "改扩建", disabled: false,}],
                zzfxyy: false,
                xmlxList: ["正选项目", "备选项目"],
                jsgmList: [{value: "JSGMGS", label: "高速", disabled: false},{value: "JSGMYJ", label: "一级", disabled: false},{value: "JSGMEJ", label: "二级", disabled: false},{value: "JSGMSJ", label: "三级", disabled: false},{value: "JSGMTDQ", label: "特大桥", disabled: false},{value: "JSGMSD", label: "隧道", disabled: false}],
                xmlbList: [{value: "审批", label: "审批", disabled: false},{value: "核准", label: "核准", disabled: false}],
                gkList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                cbsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                sgtsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                fxxmList: [{value: "是", label: "是", disabled: false},{value: "否", label: "否", disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                isIndeterminateXmxz: true,
                isIndeterminateJsgm: true,
                isIndeterminateGk: true,
                isIndeterminateCbsj: true,
                isIndeterminateSgtsj: true,
                isIndeterminateJsxz: true,
                isIndeterminateXmlx: true,
                isIndeterminateXmlb: true,
                SSWSSWGHXMID:'',
                ND:null,
                PC:null,
                oldYearId:'',
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                total1: 0, //请求记录数
                totalSqu:0,
                companyData:[],
                searchAll:[],
                chek:[],
                newAdd:false,
                editModel:{},
                showEdit:false,
                qqjzid:'',
                innerDrawerTitle:'',
                gg: '',
                GKWCD:'',
                CBSJWCD:'',
                SGTSJWCD:'',
                SGQZBWCD:'',
                isAdmin: false,
                rowData:{},
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                sswxmList:[],
            };
        },
        mounted() {
            var that = this
            this.getYearsToNow()

            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 280;
                // if (localStorage.getItem('xmmc')){
                //     this.xmmc = localStorage.getItem('xmmc')
                //     this.search()
                // }
            }, 100);
            this.getRegion();
            this.search();
            this.getSWWXMList()
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
                if(res.data.userName == 'hc'|| res.data.userName == 'zxs'){
                    this.superVip = true
                }
            })
            this.http.post('/api/Plan_invest/GetPageTotal',{}).then(res=>{
               this.totalInfo = res
            })
        },
        computed:{

        },
        methods: {
            changeYear(val){
                console.log(val)
                // var a = []
                // val.map((item,index)=>{
                //     a.push(item[0]+item[1])
                // })
                // this.JHND = a
                this.ND = val[0]
                this.PC = val[1]
                console.log(val)
            },
            getYearsToNow() {
                // this.yearsToNow=[2020,2021,2022]
                //获取到从那一年开始
                let smallYears = 2016
                //获取当前时间
                let date = new Date
                let nowYears = date.getFullYear()+1
                let Years = nowYears - smallYears
                let arrYear = []
                for (let i = 0; i <= Years; i++) {
                    arrYear.push(nowYears--)
                }
                arrYear.forEach(item => {
                    //下拉框的数组
                    this.yearsToNow.unshift({value:item,label: item,children:[
                            { value: '第一批', label: '第一批' },
                            { value: '第一批大本计划', label: '第一批大本计划'},
                            { value: '第二批', label: '第二批' },
                            { value: '第二批大本计划', label: '第二批大本计划'},
                            { value: '第三批', label: '第三批' },
                            { value: '大本计划', label: '大本计划'},
                        ]})
                })
                console.log(this.yearsToNow,'111')
                var pop = [{

                }]
            },
            toTb(val){
                console.log(val)
                    val.ISXJ = true
                val.id='00000000-0000-0000-0000-000000000000'
                val.FillingType = '1'
                this.http.post('/api/Plan_filling/AddFilling',val).then(res=>{
                    this.buttonLoading = false;
                    if(res.status){
                        this.showAddModel = false
                        this.search()
                        this.$message.success(res.message)
                    }else{
                        this.$message.error(res.message)
                    }
                })
                // localStorage.setItem('fillInfo',JSON.stringify(val))
                // setTimeout(()=>{
                //     this.$router.push('/plainFilling/heighRoad')
                // },50)
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeRoad(tab, event){
                this.currentPage = 1
                this.gllx = tab.label
                this.search()
            },
            chooseYear(val){
                console.log(val.name)
                if(val.label == '全部'){
                    this.gllx = ''
                }else{
                    this.gllx = val.label
                }
                // if(val.name == '全部'){
                //     this.jhnds = ''
                // }else{
                //     this.jhnds = val.name
                //
                // }
                this.search()
            },
            tableRowClassName({ row, rowIndex }) {
                if (row.ZJFX === '是' || row.ZDFX === '是') {
                    return 'warning-row'
                }
                return ''
            },
            changeZzfx(val){
                console.log(val)
                if(val === '是'){
                    this.zzfxyy = true
                }else{
                    this.detail.ZJFXYY = ''
                    this.zzfxyy = false
                }
            },
            showMaps(row) {
                if(row.TXMMC){
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                }
            },
            showMaps1(row) {
                if(row.TXMMC) {
                    this.dialogTableVisibleData1 = row;
                    this.dialogTableVisibleMap1 = true;
                }
            },
            changeSort(val) {
                console.log(val)
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            closeDrawer(){
                this.$refs.detailForm.resetFields()
                this.detail = {}
                console.log('关闭了')
            },
            closeDrawer1(){
                this.currentPage1 = 1
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            changeXztotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.BN_ZYCGS == null || this.detail.BN_ZYCGS ==  '' || !reg.test(this.detail.BN_ZYCGS) ? 0 : parseFloat(this.detail.BN_ZYCGS)
                var xz2 = this.detail.BN_SJBZZJ == null || this.detail.BN_SJBZZJ ==  '' || !reg.test(this.detail.BN_SJBZZJ) ? 0 : parseFloat(this.detail.BN_SJBZZJ)
                var xz3 = this.detail.BN_DFZC == null || this.detail.BN_DFZC ==  '' || !reg.test(this.detail.BN_DFZC) ? 0 : parseFloat(this.detail.BN_DFZC)
                var xz4 = this.detail.BN_CZZJ == null || this.detail.BN_CZZJ ==  '' || !reg.test(this.detail.BN_CZZJ) ? 0 : parseFloat(this.detail.BN_CZZJ)
                var xz5 = this.detail.BN_ZXZQ == null || this.detail.BN_ZXZQ ==  '' || !reg.test(this.detail.BN_ZXZQ) ? 0 : parseFloat(this.detail.BN_ZXZQ)
                var xz6 = this.detail.BN_CPYSFGGZYZFZJ == null || this.detail.BN_CPYSFGGZYZFZJ ==  '' || !reg.test(this.detail.BN_CPYSFGGZYZFZJ) ? 0 : parseFloat(this.detail.BN_CPYSFGGZYZFZJ)
                var xz7 = this.detail.BN_YHDK == null || this.detail.BN_YHDK ==  '' || !reg.test(this.detail.BN_YHDK) ? 0 : parseFloat(this.detail.BN_YHDK)
                var xz8 = this.detail.BN_QYZC == null || this.detail.BN_QYZC ==  '' || !reg.test(this.detail.BN_QYZC) ? 0 : parseFloat(this.detail.BN_QYZC)
                this.detail.BN_HJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7),xz8)
                if(this.detail.BN_HJ > 100){
                    this.$message.warning('超出本年最终投资目标,请谨慎填写')
                }
                // this.detail.xzhj = (xz1*1000/1000 +xz2*1000/1000 + xz3*1000/1000 +xz4*1000/1000 +xz5*1000/1000 + xz6*1000/1000 + xz7*1000/1000 + xz8*1000/1000)*1000/1000
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.GSGL == null || this.detail.GSGL ==  '' || !reg.test(this.detail.GSGL) ? 0 : parseFloat(this.detail.GSGL)
                var xz2 = this.detail.YJGL == null || this.detail.YJGL ==  '' || !reg.test(this.detail.YJGL) ? 0 : parseFloat(this.detail.YJGL)
                var xz3 = this.detail.EJGL == null || this.detail.EJGL ==  '' || !reg.test(this.detail.EJGL) ? 0 : parseFloat(this.detail.EJGL)
                var xz4 = this.detail.EJGL60 == null || this.detail.EJGL60 ==  '' || !reg.test(this.detail.EJGL60) ? 0 : parseFloat(this.detail.EJGL60)
                var xz5 = this.detail.SJGL == null || this.detail.SJGL ==  '' || !reg.test(this.detail.SJGL) ? 0 : parseFloat(this.detail.SJGL)
                var xz6 = this.detail.DQ == null || this.detail.DQ ==  '' || !reg.test(this.detail.DQ) ? 0 : parseFloat(this.detail.DQ)/1000
                this.detail.JSGMGJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6)
            },
            //保存
            saveCompany(){
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.newAdd ?  this.add(this.editModel) : this.update(this.editModel)

                    } else {

                        return false;
                    }
                });
            },
            //新增的
            add(params){
                params.id = '00000000-0000-0000-0000-000000000000'
                this.http.post('/api/Plan_buildcompany/AddBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //编辑的
            update(params){
                this.http.post('Plan_bu/api/ildcompany/UpdateBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })

            },
            //删除
            deletedCompany(item){
                this.http.post('/api/Plan_buildcompany/DeleteBuildcompany',item).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                console.log(this.confirmSSW)
                if (this.chek.length != 0 ){
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].ID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                }
                this.showSswxm = false
                this.sswform = {}
                this.currentPage1 = 1
            },
            //编辑
            edit(item){
                this.newAdd = false
                this.title = '修改建设单位'
                this.editModel = JSON.parse(JSON.stringify(item))
                this.showEdit = true
            },

            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                console.log(this.newAdd)
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                console.log(this.newAdd)
                // if (this.newAdd == false){
                //
                //     return false
                // }
                console.log(value)
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            chooseXm(row){
                var that = this
                this.sswform = {}
                this.showSswxm = true
                this.getSWWXMList()
                console.log(this.confirmSSW)
                if( this.confirmSSW.length == 0){
                    this.$nextTick(()=>{
                        this.$refs.multipleTable.clearSelection()
                    })
                }else{
                    setTimeout(()=>{
                        this.$nextTick(()=>{
                            that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                                    if (item.XMMC == that.confirmSSW[0].XMMC){
                                        return item
                                    }
                                }),
                                true);
                        })
                    },0)

                    // that.$refs.multipleTable.toggleRowSelection(that.chek[0],true);

                }
            },
            selectCompany(val){
                console.log(val[0])
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;
                }
            },
            getSWWXMList(){
                var that = this
                console.log(that.confirmSSW)
                this.http.post('/api/Plan_high_national_early/GetData', this.postData2()).then(res=> {
                    this.sswxmList = res.rows
                    this.total1 = res.total
                    this.$nextTick(()=>{
                        if(that.confirmSSW.length != 0){

                            that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                                    if (item.XMMC == that.confirmSSW[0].XMMC){
                                        return item
                                    }
                                }),
                                true);
                        }
                    })
                })
            },
            openTable(row){
                this.assign = true
                this.currentPage1 = 1
                this.oldYearId = row.ID
                this.getCompanyList()
            },
            getCompanyList(){
                var that = this
                this.http.post('/api/View_Plan_road_investment_old_year/GetPageData',this.postData1()).then(res=> {
                    this.companyData = res.rows
                    this.companyData.map((item,index)=>{
                        var a = []
                        if(item.FJ != '' && item.FJ != null){
                            item.FJ = item.FJ.split(';')
                            item.FJ.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.FJ = a
                        }else {
                            item.FJ = []
                        }

                    })
                })
            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.$refs.detailForm.resetFields()
                this.editStatus = false
                this.zzfxyy = false
            },
            //删除图片
            deleted(index,type){
                this.detail[type].pfwj.splice(index,1)
            },
            activeChange(e){
            },
            changeTotal(val,type){
                var that = this
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                if (val && !reg.test(val)) {
                    return false
                }
                if (type == 'nyd'){
                    if(this.detail.ydwlyd != '' && this.detail.ydwlyd != null){
                        this.totalSqu = val == '' ? that.accAdd(0,parseFloat(this.detail.ydwlyd))  :  that.accAdd( parseFloat(val) ,  parseFloat(this.detail.ydwlyd))
                    }else{
                        this.totalSqu = val == '' ? 0 : parseFloat(val)*1000/1000
                    }
                }else{
                    if(this.detail.ydnyd != '' && this.detail.ydnyd != null){
                        this.totalSqu =  val == '' ? that.accAdd(0 , parseFloat(this.detail.ydnyd)) : that.accAdd(parseFloat(val)  , parseFloat(this.detail.ydnyd))
                    }else{
                        this.totalSqu = val == '' ? 0: parseFloat(val)
                    }
                }

            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        // params.QQID ="1b7c9cb6-4fbb-4cce-80e3-66f9482806df"



                        // params.XDBS = 1
                        // params.XDJSXZ = 1



                        // params.NJHTZZQYZC = 5000
                        // params.QQID = "5f28ce04-52e8-4112-a153-d577e4889e77"
                        // if(this.confirmSSW.length >0){
                        //     params.XMID = this.confirmSSW[0].ID
                        // }
                        // params.GM_GSGL =  params.GM_GSGL== null || params.GM_GSGL ==  '' || !reg.test(params.GM_GSGL) ? 0 : parseFloat(params.GM_GSGL)
                        // params.GM_YJGL =  params.GM_YJGL== null || params.GM_YJGL ==  '' || !reg.test(params.GM_YJGL) ? 0 : parseFloat(params.GM_YJGL)
                        // params.GM_EJGL60 =  params.GM_EJGL60== null || params.GM_EJGL60 ==  '' || !reg.test(params.GM_EJGL60) ? 0 : parseFloat(params.GM_EJGL60)
                        // params.GM_EJGL80 =  params.GM_EJGL80== null || params.GM_EJGL80 ==  '' || !reg.test(params.GM_EJGL80) ? 0 : parseFloat(params.GM_EJGL80)
                        // params.GM_SANJGL =  params.GM_SANJGL== null || params.GM_SANJGL ==  '' || !reg.test(params.GM_SANJGL) ? 0 : parseFloat(params.GM_SANJGL)
                        // params.GM_SIJGL =  params.GM_SIJGL== null || params.GM_SIJGL ==  '' || !reg.test(params.GM_SIJGL) ? 0 : parseFloat(params.GM_SIJGL)
                        // params.GM_DLDQ =  params.GM_DLDQ== null || params.GM_DLDQ ==  '' || !reg.test(params.GM_DLDQ) ? 0 : parseFloat(params.GM_DLDQ)
                        // params.BN_HJ =  params.BN_HJ== null || params.BN_HJ ==  '' || !reg.test(params.BN_HJ) ? 0 : parseFloat(params.BN_HJ)
                        // params.BN_ZYCGS =  params.BN_ZYCGS== null || params.BN_ZYCGS ==  '' || !reg.test(params.BN_ZYCGS) ? 0 : parseFloat(params.BN_ZYCGS)
                        // params.BN_SJBZZJ =  params.BN_SJBZZJ== null || params.BN_SJBZZJ ==  '' || !reg.test(params.BN_SJBZZJ) ? 0 : parseFloat(params.BN_SJBZZJ)
                        // params.BN_DFZC =  params.BN_DFZC== null || params.BN_DFZC ==  '' || !reg.test(params.BN_DFZC) ? 0 : parseFloat(params.BN_DFZC)
                        // params.BN_CZZJ =  params.BN_CZZJ== null || params.BN_CZZJ ==  '' || !reg.test(params.BN_CZZJ) ? 0 : parseFloat(params.BN_CZZJ)
                        // params.BN_ZXZQ =  params.BN_ZXZQ== null || params.BN_ZXZQ ==  '' || !reg.test(params.BN_ZXZQ) ? 0 : parseFloat(params.BN_ZXZQ)
                        // params.BN_CPYSFGGZYZFZJ =  params.BN_CPYSFGGZYZFZJ== null || params.BN_CPYSFGGZYZFZJ ==  '' || !reg.test(params.BN_CPYSFGGZYZFZJ) ? 0 : parseFloat(params.BN_CPYSFGGZYZFZJ)
                        // params.BN_YHDK =  params.BN_YHDK== null || params.BN_YHDK ==  '' || !reg.test(params.BN_YHDK) ? 0 : parseFloat(params.BN_YHDK)
                        // params.BN_QYZC =  params.BN_QYZC== null || params.BN_QYZC ==  '' || !reg.test(params.BN_QYZC) ? 0 : parseFloat(params.BN_QYZC)
                        console.log(params)
                        this.http.post('/api/Plan_filling_history/UpdateHistory',params).then(res=>{
                            if(res.status){
                                this.showAddModel = false
                                this.search()
                                this.$message.success(res.message)
                            }else{
                                this.$message.error(res.message)
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            deletedItem(row){
                this.http.post('/api/Plan_filling_history/DeleteByID?id='+row.Id).then(res=>{
                    if(res.status){
                        this.search()
                        this.$message.success(res.message)
                    }else{
                        this.$message.error(res.message)
                    }
                })

            },
            cancel(){
                this.showMessage = true
                this.showEdit = false

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                console.log(file)
                let form = new FormData();
                let arrs = [];
                console.log(file);
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail[type].pfwj.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            changeCollapse(val){
            },
            chechfieldxmlx() {
                return this.gllx == "普通省道" ? false : true;
            },
            chechfieldxmlb() {
                return this.gllx == "普通省道" ? false : true;
            },
            checkfieldjsxz() {
                return this.gllx == "高速公路" ? true : false;
            },
            checkfieldxmxz(e) {
                var arr = [];
                if (this.gllx == "高速公路") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年新开工项目",
                        "“十四五”其他项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通国道") {
                    arr = [
                        "“十三五”续建项目",
                        "“十四五”新开工项目",
                        "谋划项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通省道") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年已安排计划项目",
                        "2022年建设项目",
                        "2023年建设项目",
                        "2024-2025年建设项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
            },
            moneyToNumFiled(money){
                if(money == 0 || !money) return 0;
                return money;

                // var num=money.trim();
                // var ss=num.toString();
                // if(ss.length==0){
                //     return 0.00;
                // }
                // num=new Number(ss.replace(/,/g, ""));
                // return parseFloat(num);
            },
            closeDetail(){
                this.showDetail = false
                this.detail = {}

            },
                async openDetailsEdit(row) {
                console.log(row)
                var that = this
                this.editStatus = false
                this.showAddModel = true
                this.activeName = '0'
                this.addStatus = false
                this.detail = JSON.parse(JSON.stringify(row))
                    this.detail.SYZJHJ = this.moneyToNumFiled(this.detail.JHZZJHJ) -  this.moneyToNumFiled(this.detail.LJXDHJ) -  this.moneyToNumFiled(this.detail.LJWCTZHJ) -  this.moneyToNumFiled(this.detail.NJHTZHJ)
                    this.detail.SYZJZYTZ = this.moneyToNumFiled(this.detail.JHZZJZYTZ) -  this.moneyToNumFiled(this.detail.LJXDZYTZ) -  this.moneyToNumFiled(this.detail.LJWCTZZYTZ) -  this.moneyToNumFiled(this.detail.NJHTZZYTZ)
                    this.detail.SYZJCZZJ = this.moneyToNumFiled(this.detail.JHZZJCZZJ) -  this.moneyToNumFiled(this.detail.LJXDCZZJ) -  this.moneyToNumFiled(this.detail.LJWCTZCZZJ) -  this.moneyToNumFiled(this.detail.NJHTZCZZJ)
                    this.detail.SYZJSJBZZJ = this.moneyToNumFiled(this.detail.SJBZZJ) -  this.moneyToNumFiled(this.detail.LJXDSJBZZJ) -  this.moneyToNumFiled(this.detail.LJWCTZSJBZZJ) -  this.moneyToNumFiled(this.detail.NJHTZSJBZZJ)
                    this.detail.SYZJCPYSF = this.moneyToNumFiled(this.detail.JHZZJCPYSF) -  this.moneyToNumFiled(this.detail.LJXDCPYSF) -  this.moneyToNumFiled(this.detail.LJWCTZCPYSF) -  this.moneyToNumFiled(this.detail.NJHTZCPYSF)
                    this.detail.SYZJZXZQ = this.moneyToNumFiled(this.detail.JHZZJZXZQ) -  this.moneyToNumFiled(this.detail.LJXDZXZQ) -  this.moneyToNumFiled(this.detail.LJWCTZZXZQ) -  this.moneyToNumFiled(this.detail.NJHTZZXZQ)
                    this.detail.SYZJZXZQ = this.moneyToNumFiled(this.detail.JHZZJZXZQ) -  this.moneyToNumFiled(this.detail.LJXDZXZQ) -  this.moneyToNumFiled(this.detail.LJWCTZZXZQ) -  this.moneyToNumFiled(this.detail.NJHTZZXZQ)
                    this.detail.SYZJSSXZC = this.moneyToNumFiled(this.detail.JHZZJSSXZC) -  this.moneyToNumFiled(this.detail.LJXDSSXZC) -  this.moneyToNumFiled(this.detail.LJWCTZSSXZC) -  this.moneyToNumFiled(this.detail.NJHTZSSXZC)
                    this.detail.SYZJYHDK= this.moneyToNumFiled(this.detail.JHZZJYHDK) -  this.moneyToNumFiled(this.detail.LJXDYHDK) -  this.moneyToNumFiled(this.detail.LJWCTZYHDK) -  this.moneyToNumFiled(this.detail.NJHTZYHDK)
                    this.detail.SYZJZQYZC= this.moneyToNumFiled(this.detail.JHZZJQYZC) -  this.moneyToNumFiled(this.detail.LJXDZQYZC) -  this.moneyToNumFiled(this.detail.LJWCTZZQYZC) -  this.moneyToNumFiled(this.detail.NJHTZZQYZC)
                    this.detail.SYZJDFZC= this.moneyToNumFiled(this.detail.JHZZJDFZC) -  this.moneyToNumFiled(this.detail.LJXDDFZC) -  this.moneyToNumFiled(this.detail.LJWCTZDFZC) -  this.moneyToNumFiled(this.detail.NJHTZDFZC)
            },
            exportData() {
                var that = this
                this.http.post("/api/Plan_filling_history/PlanFillingHistoryTemplateWrite",this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" +'Plan_filling_history'+ "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                console.log('qingqiuzomg')
                // this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_filling_history/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postData() {
                var SZS = "";
                var SZX = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    SZS = reg[0];
                    if (reg.length > 1) {
                        SZS = reg[1];
                    }
                }
                //所属地市
                var query_ssds = {
                    Name: "SZS",
                    Value: SZS,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: SZX,
                    DisplayType: "like",
                };
                //计划年度
                var query_jhnd = {
                    Name: "JHND",
                    Value: this.ND,
                    DisplayType: "equal",
                };
                //计划批次
                var query_jhpc = {
                    Name: "JHPC",
                    Value: this.PC,
                    DisplayType: "Equal",
                };
                //建设类别
                var query_gllx = {
                    Name: "XMLB",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                var jsxzArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsxzArray.push(element);
                });
                var query_jsxs= {
                    Name: "JSXZ",
                    Value: jsxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //风险项目
                var fxxmArray = [];
                this.checkedFxxm.forEach((element) => {
                    fxxmArray.push(element);
                });
                var query_fxxm = {
                    Name: "XMFX",
                    Value: fxxmArray.join(","),
                    DisplayType: "Equal",
                };

                //法人单位
                var query_frdw = {
                    Name: "TBDW",
                    Value: this.frdw,
                    DisplayType: "like",
                };
                //建设规模
                var jsgmArray = [];
                this.checkedJsgm.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "JSGMZT",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };

                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "JHND",
                    Order: "desc",
                    wheres: JSON.stringify([
                        query_jhnd,
                        query_jhpc,
                        query_gllx,
                        query_ssds,
                        query_ssx,
                        query_frdw,
                        query_xmmc,
                        query_jsxs,
                        query_jsgm,
                        query_fxxm
                    ]),
                };
                return postData;
            },
            postData1(){

                //项目名称
                var query_year = {
                    Name: "Year",
                    Value: this.formInline.year,
                    DisplayType: "Equal",
                };
                var query_Id = {
                    Name: "Plan_Road_Inv_ID",
                    Value: this.oldYearId,
                    DisplayType: "Equal",
                };


                var postData = {
                    page: 1,
                    rows: 20,
                    Sort: "DSBM",
                    Order: "desc",
                    wheres: JSON.stringify([
                        query_year,
                        query_Id
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            postData2(){

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.sswform.XMMC,
                    DisplayType: "like",
                };
                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.gllx = "";
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
            },
            handleChange(value) {
                this.checkedXmxz = [];
                this.search();
            },
            handleCheckAllXmxzChange(val) {
                if (val) {
                    if (this.gllx == "高速公路") {
                        this.checkedXmxz = [
                            "“十三五”续建项目",
                            "2021年新开工项目",
                            "“十四五”其他项目",
                            "“十四五”谋划项目",
                        ];
                    } else if (this.gllx == "普通国道") {
                        this.checkedXmxz = [
                            "“十三五”续建项目",
                            "“十四五”新开工项目",
                            "谋划项目",
                            "“十四五”谋划项目",
                        ];
                    } else if (this.gllx == "普通省道") {
                        this.checkedXmxz = [
                            "“十三五”续建项目",
                            "2021年已安排计划项目",
                            "2022年建设项目",
                            "2023年建设项目",
                            "2024-2025年建设项目",
                        ];
                    } else {
                        this.checkedXmxz = this.xmxzList;
                    }
                } else {
                    this.checkedXmxz = [];
                }
                this.isIndeterminateXmxz = false;
            },
            handleCheckAllJsxzChange(val) {
                this.checkedJsxz = val ? this.jsxzList : [];
                this.isIndeterminateJsxz = false;
            },
            //项目类别
            handleCheckAllXmlbChange(val){
                this.checkedXmlb = val ? this.xmlbList : [];
                this.isIndeterminateXmlb = false;
            },
            //建设规模
            handleCheckAllJsgmChange(val){
                this.checkedJsgm = val ? this.jsgmList : [];
                this.isIndeterminateJsgm = false;
            },
            //工可
            handleCheckAllGkChange(val){
                this.checkedGk = val ? this.gkList : [];
                this.isIndeterminateGk = false;
            },
            //初步设计
            handleCheckAllCbsjChange(val){
                this.checkedCbsj = val ? this.cbsjList : [];
                this.isIndeterminateCbsj = false;
            },
            //施工图设计
            handleCheckAllSgtsjChange(val){
                this.checkedSgtsj = val ? this.sgtsjList : [];
                this.isIndeterminateSgtsj = false;
            },
            handleCheckAllXmlxChange(val) {
                if (this.gllx == "普通省道") {
                    this.checkedXmlx = val ? this.xmlxList : [];
                }
                this.isIndeterminateXmlx = false;
            },
            handleCheckedXmxzChange(value) {
                let checkedCount = value.length;
                this.checkAllXmxz = checkedCount === this.xmxzList.length;
                this.isIndeterminateXmxz =
                    checkedCount > 0 && checkedCount < this.xmxzList.length;
            },
            handleCheckedJsxzChange(value) {
                let checkedCount = value.length;
                this.checkAllJsxz = checkedCount === this.jsxzList.length;
                this.isIndeterminateJsxz =
                    checkedCount > 0 && checkedCount < this.jsxzList.length;
            },
            handleCheckedXmlbChange(value){
                let checkedCount = value.length;
                this.checkAllXmlb = checkedCount === this.xmlbList.length;
                this.isIndeterminateXmlb =
                    checkedCount > 0 && checkedCount < this.xmlbList.length;
            },
            handleCheckedJsgmChange(value){
                let checkedCount = value.length;
                this.checkAllJsgm = checkedCount === this.jsgmList.length;
                this.isIndeterminateJsgm =
                    checkedCount > 0 && checkedCount < this.jsgmList.length;
            },
            handleCheckedXmlxChange(value) {
                let checkedCount = value.length;
                this.checkAllXmlx = checkedCount === this.xmlxList.length;
                this.isIndeterminateXmlx =
                    checkedCount > 0 && checkedCount < this.xmlxList.length;
            },
            handleCheckedGkChange(value) {
                let checkedCount = value.length;
                this.checkAllGk = checkedCount === this.gkList.length;
                this.isIndeterminateGk =
                    checkedCount > 0 && checkedCount < this.gkList.length;
            },
            handleCheckedCbsjChange(value) {
                let checkedCount = value.length;
                this.checkAllCbsj = checkedCount === this.cbsjList.length;
                this.isIndeterminateCbsj =
                    checkedCount > 0 && checkedCount < this.cbsjList.length;
            },
            handleCheckedSgtsjChange(value) {
                let checkedCount = value.length;
                this.checkAllSgtsj = checkedCount === this.sgtsjList.length;
                this.isIndeterminateSgtsj =
                    checkedCount > 0 && checkedCount < this.sgtsjList.length;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getSWWXMList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getSWWXMList()

            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
    .warning-row {
        background:#fef9f9 !important;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /*/deep/.el-collapse-item__content {*/
        /*    padding:10px 15px 20px 15px  !important;*/
        /*}*/
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
                height: 35px;
                line-height: 35px;
            }

        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }


    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*/deep/ .el-input--suffix{*/
    /*    width: 178px;*/
    /*}*/
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }
    /deep/.el-form--inline .el-form-item{
        margin-right: 0;
    }
    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }

    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 5px;
        .dwmc{
            padding-left: 15px;
            text-align: left;
            width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 35px;
            background: #ffffff;
            border-radius: 5px 0 0 5px;
            line-height: 35px;

        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 35px;
            background: #fafafa;
            border-radius: 0 5px 5px 0;
            i{
                font-size: 16px;
            }
        }
    }

    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #f5f7fa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        height: 42px;
        background: #f5f7fa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 42px;
        background: #f5f7fa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 185px;
        height: 42px;
        text-align: center;
        line-height: 36px;
    }
    .cont2{
        height: 42px;
        text-align: center;
        line-height: 36px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .w20{
        width: 220px;
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 0;
            width: 330px;
            background: #F2F8FE;
            height: 1000px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }


    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 20px;
            width: 330px;
            background: #F2F8FE;
            min-height: 780px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
    .newform{
        /deep/.el-input__inner{
            width: 100% !important;
            padding: 0 5px !important;
        }
        /deep/.el-form-item__error{
            position: absolute !important;
            top: -2px;
        }
        /deep/.el-select{

            /deep/.el-input--suffix{
                width: 100% !important;
                /deep/.el-input__inner{
                    text-align: right;
                    // color: #C0C4CC;
                    font-size: 14px;
                    padding-right: 30px !important;

                }

            }
            /deep/.is-disabled{

                /deep/.el-input__inner{
                    background-color: #F5F7FA !important;

                }
            }
        }
    }
    .newre{
        display: inline-block;
        background: #409eff;
        padding: 0px 5px;
        border-radius: 50px;
        margin: 0 9px;
        position: relative;
        top: 1px;
        /deep/.el-input__suffix{
            right: 9px;
            top: -1px;
        }
        /deep/.el-tag{
            background: none !important;
        }
        /deep/.el-tag:not(:first-child){
            display: none;
        }
        /deep/.el-button--mini{
            position: relative;
            top: -1px;
            padding: 3px 0 !important;
        }
        /deep/.el-input__inner{
            width: 120px !important;
            height: 20px !important;
            border-radius: 50px;
        }
        /deep/.el-input--suffix{
            width: 100% !important;
            .el-input__icon{
                line-height: 30px;
            }
        }
    }
    .colors{
        /deep/.el-input__inner{
            color: #f00;
        }
    }
    /deep/input [type="number"]{
        -moz-appearance:textfield;
    }
    /deep/input::-webkit-outer-spin-button{
        -webkit-appearance: none !important;
    }
    /deep/input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    .inputcenter{
        /deep/.el-input__inner{
            text-align: center;
        }
    }
    .inputright{
        /deep/.el-input__inner{
            text-align: right;
        }
    }
    /deep/.is-disabled{
        .el-input__inner{
            background-color: #F5F7FA !important;
        }

    }
    .dis{
        background-color: #F5F7FA !important;
    }
    .xxh{
        display: inline-block;
        width: 26.5%;
        /deep/.el-input__inner{

            border: 0;
            border-bottom: 1px #dcdfe6 solid;
            width: 100% !important;
        }
    }
</style>
